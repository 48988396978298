import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import api from "api";
import FileDownload from "js-file-download";

// styling
import {
  DetailsWrapper,
  SidebarWrapper,
  Content,
  InvoiceSection,
  Sidebar,
  Paging,
  TableSection,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableData,
  TableHeaderConatiner,
} from "../style.js";

// material icons
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

import Editable from "@ui/Dox/Editable/index.js";
import DoxBtn from "@ui/Dox/DoxBtn";
import WidgetsLoader from "@components/WidgetsLoader";
import { useAuth } from "@contexts/AuthProvider";
import { nanoid } from "nanoid";
import CanvasImage from "../CanvasImage.jsx";
import instance from "@utils/instance.js";
import DoxIconBtn from "@ui/Dox/DoxIconBtn/index.jsx";
import DownloadIcon from "@ui/Dox/DownloadIcon/index.jsx";
import { useInterfaceContext } from "@contexts/interfaceContext.js";
import EditableMultiline from "@ui/Dox/EditableMultiline/index.js";
import CustomSelectBox from "@ui/Dox/CustomSelectBox/index.jsx";
import CustomSearchDropdown from "@ui/Dox/CustomSearchDropdown/index.jsx";
import CustomDropDownPopUp from "@ui/Dox/CustomDropDownPopup/index.jsx";
import Swal from "sweetalert2";
import toastAlert from "@widgets/Dox/CustomToastAlert";

const DocumentDetail = ({ state }) => {
  const [data, setData] = useState({});

  const [moderated, setModerated] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [pagesLength, setPagesLength] = useState(1);
  const [pageDetails, setPageDetails] = useState([]);
  const [pageFields, setPageFields] = useState([]);
  // const [newPageFields, setNewPageFields] = useState([]);
  const [categorisedFields, setCategorisedFields] = useState([]);
  const [allFields, setAllFields] = useState([]);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [fieldLabels, setFieldLabels] = useState([]);
  const [tableData, setTableData] = useState({});

  const [categoryLabels, setCategoryLabels] = useState([]);
  const [category, setCategory] = useState([]);
  const [selectedTableRows, setSelectedTableRows] = useState([]);
  const [showAddRowBtn, setShowAddRowBtn] = useState(false);
  const [showDeleteRowBtn, setShowDeleteRowBtn] = useState(false);
  const [tableRowInfo, setTableRowInfo] = useState({
    maxRow: [],
  });

  const [buyerName, setBuyerName] = useState("");
  const [tableItems, setTableItems] = useState([]);
  const [tableAmount, setTableAmount] = useState([]);
  const [isShowQB, setIsShowQB] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false);
  const auth = useAuth();
  const {
    notification,
    setNotification,
    showIntegratedApps,
    qbItems,
    setQBItems,
    qbCustomer,
    setQBCustomer,
  } = useInterfaceContext();
  const { payload, config, ext } = state;

  const sidebarRef = useRef(null);
  const [isResizing, setIsResizing] = useState(false);
  const [sidebarWidth, setSidebarWidth] = useState(500);

  const startResizing = React.useCallback((mouseDownEvent) => {
    setIsResizing(true);
  }, []);

  const stopResizing = React.useCallback(() => {
    setIsResizing(false);
  }, []);

  const resize = React.useCallback(
    (mouseMoveEvent) => {
      if (isResizing) {
        setSidebarWidth(
          sidebarRef.current.getBoundingClientRect().right -
            mouseMoveEvent.clientX
        );
      }
    },
    [isResizing]
  );

  useEffect(() => {
    // pointerEvents works for both mouse and touch
    window.addEventListener("pointermove", resize);
    window.addEventListener("pointerup", stopResizing);
    return () => {
      window.removeEventListener("pointermove", resize);
      window.removeEventListener("pointerup", stopResizing);
    };
  }, [resize, stopResizing]);

  const body = {
    companyId: payload.companyId,
    documentId: payload.documentId,
    documentType: payload.documentSubType,
  };

  const initialState = {};

  category.forEach((category) => {
    initialState[category] = "";
  });

  const [customSection, setCustomSection] = useState(initialState);

  const isEmptyObject = (obj) => Object.keys(obj).length === 0;

  const updateField = (e, id, isTable = false) => {
    if (e.key === "Enter" || e.type === "blur") {
      let updatedFields = [];
      let updatedTableFields = [];
      let newFields = [];
      if (isTable) {
        for (let i = 0; i < categorisedFields.length; i++) {
          if ("table" in categorisedFields[i]) {
            // Find the object with the matching group i.e."table"
            const dataObject = categorisedFields[i]["table"].data.find((item) =>
              item.cells.some((cell) => cell.id === id)
            );
            // If the object is found, update the text property in the cells array
            if (dataObject && "cells" in dataObject) {
              // Find the cell with the matching id
              const cellToUpdate = dataObject.cells.find(
                (cell) => cell.id === id
              );

              // If the cell is found, update the text property
              if (cellToUpdate) {
                cellToUpdate.text = e.target.value;
              }
            }
          }
        }
      } else {
        for (let i = 0; i < categorisedFields.length; i++) {
          // Check if the current object has the specified key
          if (e.target.id in categorisedFields[i]) {
            // Find the object with the matching ID
            const dataObject = categorisedFields[i][e.target.id].data.find(
              (item) => item.id === id
            );

            // If the object is found, update the ocr_text property
            if (dataObject) {
              dataObject.ocr_text = e.target.value;
            }
          }
        }
      }

      if (categorisedFields) {
        const allGroupFields = [];
        categorisedFields &&
          categorisedFields.forEach((group) => {
            const groupData = group[Object.keys(group)[0]].data;
            allGroupFields.push(...groupData);
          });
        setAllFields(allGroupFields);
      }

      const currentPageInfo = data.Pages.filter(
        (ele) => ele.pageNo === pageNumber
      )[0];
      // currentPageInfo.moderated = updatedFields;
      currentPageInfo.moderated = categorisedFields;

      let moderatedFields = data.Pages.map((page) =>
        page.moderated.length > 0
          ? { id: page._id, moderated: page.moderated }
          : { id: page._id, moderated: page.transformed }
      );

      // setPageFields(updatedFields);
      setPageFields(categorisedFields);
      setModerated(moderatedFields);
      if (isTable) {
        // setCurrentTableData(updatedFields);
        setCurrentTableData(categorisedFields);
      }
    }
  };

  const updateByBox = (e, obj, previousObj, isResized = false) => {
    //delete previous group obj if we change group
    if (obj.label != previousObj.label) {
      for (let i = 0; i < categorisedFields.length; i++) {
        for (const key in categorisedFields[i]) {
          const categoryData = categorisedFields[i][key]?.data;

          if (categoryData) {
            const objectToDeleteInd = categoryData.findIndex(
              (item) => item.id === previousObj.id
            );
            if (objectToDeleteInd !== -1) {
              // If the object is found, remove it from the array
              categoryData.splice(objectToDeleteInd, 1);
            }
          }
        }
      }
    }

    if (isResized && !pageFields.find((ele) => ele.id === obj.id)) {
      return;
    }

    let updatedFields = [];
    let updatedTableFields = [];
    if (obj.row) {
      for (let i = 0; i < categorisedFields.length; i++) {
        if ("table" in categorisedFields[i]) {
          // Find the object with the matching group i.e."table"
          const dataObject = categorisedFields[i]["table"].data.find((item) =>
            item.cells.some((cell) => cell.id === obj.id)
          );

          // If the object is found, update the text property in the cells array
          if (dataObject && "cells" in dataObject) {
            // Find the cell with the matching id
            const cellToUpdate = dataObject.cells.find(
              (cell) => cell.id === obj.id
            );
            // If the cell is found, update the text property and all coordinates
            if (cellToUpdate) {
              cellToUpdate.text = obj.text;
              cellToUpdate.xmax = obj.xmax;
              cellToUpdate.xmin = obj.xmin;
              cellToUpdate.ymax = obj.ymax;
              cellToUpdate.ymin = obj.ymin;
            }
          } else if (obj.isNew === true) {
            //find the obj with same row
            const dataObject = categorisedFields[i]["table"].data.find((item) =>
              item.cells.some((cell) => cell.row === obj.row)
            );

            if (dataObject && "cells" in dataObject) {
              // Find the cell with the matching row and label
              const cellToUpdate = dataObject.cells.find(
                (cell) => cell.row === obj.row && cell.label === obj.label
              );
              // If the cell is found, update the text property and all coordinates
              if (cellToUpdate) {
                cellToUpdate.text = obj.text;
                cellToUpdate.xmax = obj.xmax;
                cellToUpdate.xmin = obj.xmin;
                cellToUpdate.ymax = obj.ymax;
                cellToUpdate.ymin = obj.ymin;
              }
            } else {
              toastAlert("Entered row is not present", "error");
            }
          }
        }
      }
    } else if (obj.type || obj.isNew === true) {
      categoryLabels.forEach((item) => {
        if (item.key === obj.label) {
          let foundGroup = false;
          for (let i = 0; i < categorisedFields.length; i++) {
            if (item.group in categorisedFields[i]) {
              foundGroup = true;
              const categoryData = categorisedFields[i][item.group].data;
              const dataObject = categoryData.find(
                (item) => item.id === obj.id || item.label === obj.label
              );

              if (obj.isNew === true) {
                if (dataObject) {
                  dataObject.ocr_text = obj.ocr_text;
                  dataObject.xmin = obj.xmin;
                  dataObject.xmax = obj.xmax;
                  dataObject.ymin = obj.ymin;
                  dataObject.ymax = obj.ymax;
                } else {
                  obj = { ...obj, page_no: pageNumber, key: item.key };
                  categoryData.push(obj);
                }
              } else {
                if (obj.isNew !== true) {
                  if (dataObject) {
                    dataObject.ocr_text = obj.ocr_text;
                    dataObject.xmin = obj.xmin;
                    dataObject.xmax = obj.xmax;
                    dataObject.ymin = obj.ymin;
                    dataObject.ymax = obj.ymax;
                  } else {
                    categoryData.push(obj);
                  }
                }
              }
              break;
            }
          }

          // If the group is not found, create a new group object named newGroup and push the object
          if (!foundGroup) {
            setCustomSection({ ...customSection, [item.group]: item.groupVal });

            const newGroup = {
              [item.group]: { groupVal: item.groupVal, data: [] },
            };
            obj = { ...obj, page_no: pageNumber, key: item.key };
            newGroup[item.group].data.push(obj);
            categorisedFields.push(newGroup);
          }
        }
      });
    }

    if (categorisedFields) {
      const allGroupFields = [];
      categorisedFields &&
        categorisedFields.forEach((group) => {
          const groupData = group[Object.keys(group)[0]].data;
          allGroupFields.push(...groupData);
        });
      setAllFields(allGroupFields);
    }

    const currentPageInfo = data.Pages.filter(
      (ele) => ele.pageNo === pageNumber
    )[0];
    // currentPageInfo.moderated = updatedFields;
    currentPageInfo.moderated = categorisedFields;

    // get fields of all pages to updateDocuments as moderated
    const moderatedFields = data.Pages.map((page) =>
      page.moderated.length > 0
        ? { id: page._id, moderated: page.moderated }
        : { id: page._id, moderated: page.transformed }
    );
    // setPageFields(updatedFields);
    setPageFields(categorisedFields);
    setModerated(moderatedFields);
    if (obj.row) {
      setCurrentTableData(categorisedFields);
    }
  };

  const deleteByBox = (e, obj) => {
    // if (!pageFields.find((ele) => ele.id === obj.id)) {
    //   return;
    // }
    let updatedFields = [];
    let updatedTableFields = [];
    if (obj.row) {
      for (let i = 0; i < categorisedFields.length; i++) {
        if ("table" in categorisedFields[i]) {
          // Find the object with the matching group i.e."table"
          const dataObject = categorisedFields[i]["table"].data.find((item) =>
            item.cells.some((cell) => cell.id === obj.id)
          );

          // If the object is found, update the text property in the cells array
          if (dataObject && "cells" in dataObject) {
            // Find the cell with the matching id
            const cellToUpdate = dataObject.cells.find(
              (cell) => cell.id === obj.id
            );
            // If the cell is found, update the text property as empty
            if (cellToUpdate) {
              cellToUpdate.text = "";
            }
          }
        }
      }
    } else if (obj.type || obj.isNew === true) {
      for (let i = 0; i < categorisedFields.length; i++) {
        const category = categorisedFields[i];

        for (const key in category) {
          const dataObjectIndex = category[key].data.findIndex(
            (item) => item.id === obj.id
          );

          if (dataObjectIndex !== -1) {
            // Object found, delete it
            category[key].data.splice(dataObjectIndex, 1);
          }
        }
      }
    }

    if (categorisedFields) {
      const allGroupFields = [];
      categorisedFields &&
        categorisedFields.forEach((group) => {
          const groupData = group[Object.keys(group)[0]].data;
          allGroupFields.push(...groupData);
        });
      setAllFields(allGroupFields);
    }

    const currentPageInfo = data.Pages.filter(
      (ele) => ele.pageNo === pageNumber
    )[0];
    // currentPageInfo.moderated = updatedFields;
    currentPageInfo.moderated = categorisedFields;

    // get fields of all pages to updateDocuments as moderated
    const moderatedFields = data.Pages.map((page) =>
      page.moderated.length > 0
        ? { id: page._id, moderated: page.moderated }
        : { id: page._id, moderated: page.transformed }
    );
    // setPageFields(updatedFields);
    setPageFields(categorisedFields);
    setModerated(moderatedFields);
    if (obj.row) {
      // setCurrentTableData(updatedTableFields);
      setCurrentTableData(categorisedFields);
    }
  };

  const goBack = () => {
    navigate(-1);
  };

  const submitHandler = async () => {
    let obj = {
      moderatedFields: moderated,
    };
    //all pages table row to check qbItems length
    let allPageTableRows = 0;

    // const noOfRows =
    //   tableRowInfo.maxRow && tableRowInfo.maxRow.map((rowNumber) => rowNumber);

    let qbData = {
      items: qbItems && qbItems,
      customer: qbCustomer && qbCustomer,
    };

    if (moderated.length === 0) {
      //when doc is not moderated
      // get fields of all pages to updateDocuments as moderated
      const moderatedFields = data.Pages.map((page) =>
        page.moderated.length > 0
          ? { _id: page._id, moderated: page.moderated }
          : { _id: page._id, moderated: page.transformed }
      );
      moderatedFields.map((ele) => {
        let moderatedArr = ele.moderated;
        for (let i = 0; i < moderatedArr.length; i++) {
          if ("table" in moderatedArr[i]) {
            // Find the object with the matching group i.e."table"
            const dataObject = moderatedArr[i]["table"].data[0];

            if (dataObject) {
              const distinctRows = [
                ...new Set(
                  dataObject.cells && dataObject.cells.map((item) => item.row)
                ),
              ];
              allPageTableRows += distinctRows.length;
            }
          }
        }
      });

      let updatedModeratedFields = moderatedFields.map((ele) => {
        const allGroupFields = [];
        ele.moderated &&
          ele.moderated.forEach((group) => {
            const groupData = group[Object.keys(group)[0]].data;
            allGroupFields.push(...groupData);
          });
        return { _id: ele._id, moderated: allGroupFields };
      });
      //just update updatedModeratedFields with moderatedFields
      // obj.moderatedFields = moderatedFields;
      obj.moderatedFields = updatedModeratedFields;
    } else {
      //when doc is moderated
      obj.moderatedFields.map((ele) => {
        let moderatedArr = ele.moderated;
        for (let i = 0; i < moderatedArr.length; i++) {
          if ("table" in moderatedArr[i]) {
            // Find the object with the matching group i.e."table"
            const dataObject = moderatedArr[i]["table"].data[0];
            if (dataObject) {
              const distinctRows = [
                ...new Set(
                  dataObject.cells && dataObject.cells.map((item) => item.row)
                ),
              ];
              allPageTableRows += distinctRows.length;
            }
          }
        }
      });

      let updatedModeratedFields = obj.moderatedFields.map((ele) => {
        const allGroupFields = [];
        ele.moderated &&
          ele.moderated.forEach((group) => {
            const groupData = group[Object.keys(group)[0]].data;
            allGroupFields.push(...groupData);
          });
        return { _id: ele.id, moderated: allGroupFields };
      });
      obj.moderatedFields = updatedModeratedFields;
    }

    if (
      allPageTableRows === qbItems.length &&
      Object.keys(qbCustomer).length > 0 &&
      qbItems.length > 0 &&
      body.documentType === "Invoice"
    ) {
      obj = {
        ...obj,
        quickBooksOnline: qbData,
      };
      try {
        setIsLoading(true);
        const res = await api.put(
          `document/update-document/${data._id}`,
          { ...obj },
          {
            headers: {
              "x-access-token": auth.user.accessToken,
            },
          }
        );
        // set notification for new file uploaded
        const oldNotify = notification.isMarkAsRead
          ? notification.notifications.slice(0, 5)
          : notification.notifications;
        const notifications = [
          {
            id: nanoid(),
            type: body.documentType,
            msg: `${payload.fileName} is reviewd by ${auth.userInfo.userData.name}.`,
            tab: "Reviewed",
          },
          ...oldNotify,
        ];

        setNotification({
          isMarkAsRead: false,
          notifications: notifications,
        });
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);

        obj = {
          ...obj,
          quickBooksOnline: {},
        };
      }
      goBack();
    } else if (
      body.documentType === "Invoice" &&
      showIntegratedApps.quickbooksOnline
    ) {
      Swal.fire({
        title: "Are you sure ?",
        text: "All QuickBooks fields are not linked !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        // confirmButtonText: "Yes, Submit !",
        confirmButtonText: "Submit , anyway !",
        closeOnConfirm: true,
      }).then((result) => {
        if (result.isConfirmed) {
          // Swal.fire("Submitted!", "Your file has been Submitted.", "success");
          obj = {
            ...obj,
            quickBooksOnline: {},
          };

          try {
            setIsLoading(true);
            const res = api.put(
              `document/update-document/${data._id}`,
              { ...obj },
              {
                headers: {
                  "x-access-token": auth.user.accessToken,
                },
              }
            );
            // set notification for new file uploaded
            const oldNotify = notification.isMarkAsRead
              ? notification.notifications.slice(0, 5)
              : notification.notifications;
            const notifications = [
              {
                id: nanoid(),
                type: body.documentType,
                msg: `${payload.fileName} is reviewd by ${auth.userInfo.userData.name}.`,
                tab: "Reviewed",
              },
              ...oldNotify,
            ];

            setNotification({
              isMarkAsRead: false,
              notifications: notifications,
            });
          } catch (e) {
            console.log(e);
          } finally {
            setIsLoading(false);
            obj = {
              ...obj,
              quickBooksOnline: {},
            };
          }
          goBack();
        }
      });
    } else {
      try {
        setIsLoading(true);
        const res = await api.put(
          `document/update-document/${data._id}`,
          { ...obj },
          {
            headers: {
              "x-access-token": auth.user.accessToken,
            },
          }
        );
        // set notification for new file uploaded
        const oldNotify = notification.isMarkAsRead
          ? notification.notifications.slice(0, 5)
          : notification.notifications;
        const notifications = [
          {
            id: nanoid(),
            type: body.documentType,
            msg: `${payload.fileName} is reviewd by ${auth.userInfo.userData.name}.`,
            tab: "Reviewed",
          },
          ...oldNotify,
        ];

        setNotification({
          isMarkAsRead: false,
          notifications: notifications,
        });
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
      goBack();
    }
  };

  //get all details od document intially
  const getDetails = async () => {
    let fileInfo;
    try {
      setIsLoading(true);
      const res = await instance.get(
        `/document/show-document/${body.documentId}`
      );
      // console.log("response ==>>", res.data);
      fileInfo = res.data;
      let updatedCategory = fileInfo.data.documentLabelGroup;
      updatedCategory = updatedCategory?.filter((ele) => ele !== "table");
      const allLabels = fileInfo.data.documentLabels;
      const pageData = fileInfo.data.result;
      const allFields = pageData.Pages[0].transformed;
      const headerLabels = fileInfo.data.documentTables;
      const feildLabels = fileInfo.data.documentLabels;
      setFieldLabels(feildLabels);
      setTableHeaders(headerLabels);
      setCategory(updatedCategory);
      if (allFields) {
        const customSection = {};
        allFields.forEach((field) => {
          const category = Object.keys(field).find((key) => key !== "groupVal");
          if (category) {
            customSection[category] = field[category].groupVal;
          }
        });

        setCustomSection(customSection);
      }

      setData(pageData);
      setCategoryLabels(allLabels);
      setPagesLength(pageData.Pages.length);
      getCurrentPageDetails(pageData);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  // get csv file
  const getCsv = async () => {
    const URL = `${process.env.REACT_APP_BASE_URL}/document/download-documents`;
    // download CSV api call
    instance({
      url: URL,
      method: "post",
      responseType: "blob",
      headers: { "x-access-token": auth.user.accessToken },
      data: {
        documentIds: [payload.documentId],
      },
    })
      .then((resp) => {
        FileDownload(resp.data, "Extracted.csv");
      })
      .catch((e) => console.log(e));
  };

  const getCurrentPageDetails = (data) => {
    const page =
      data.Pages && data.Pages.filter((ele) => ele.pageNo === pageNumber)[0];
    setPageDetails(page);

    const fields =
      page && page.moderated.length > 0
        ? page && page.moderated
        : page && page.transformed;
    setPageFields(fields);
    setCategorisedFields(fields);
    if (fields) {
      const allGroupFields = [];
      fields &&
        fields.forEach((group) => {
          const groupData = group[Object.keys(group)[0]].data;
          allGroupFields.push(...groupData);
        });
      setAllFields(allGroupFields);
    }
    setCurrentTableData(fields);
  };

  useEffect(() => {
    allFields.map((ele) => {
      if (ele.label === "buyer_name") {
        setBuyerName(ele.ocr_text);
      }
    });
  }, [allFields]);

  const updateSectionHandler = async (e) => {
    if (e.target.value !== "") {
      setCustomSection({
        ...customSection,
        [e.target.id]: e.target.value,
      });
      const categoryLabels =
        body.documentType === "Invoice"
          ? "invoiceLabels"
          : body.documentType === "BillOfLading"
          ? "billOfLadingLabels"
          : body.documentType === "PurchaseOrder"
          ? "purchaseOrderLabels"
          : body.documentType === "Passport"
          ? "passportLabels"
          : body.documentType === "DrivingLicense"
          ? "drivingLicenseLabels"
          : body.documentType === "BankStatement"
          ? "bankStatementLabels"
          : [];
      try {
        const res = await instance.post(
          "/document/updateCustomSection",
          {
            LabelName: categoryLabels,
            currentGroupName: e.target.id,
            newGroupName: e.target.value,
          },
          {
            headers: {
              "x-access-token": auth.user.accessToken,
            },
          }
        );
      } catch (e) {
        console.log(e);
      }
    }
  };

  const changeHeaderHandler = (e) => {
    if (!auth.isAdmin()) {
      return;
    }
    const id = e.target.name;
    const selected = tableHeaders.filter(
      (ele) => ele.value === e.target.value
    )[0];

    const selectedCell = tableData.tableHeaders.filter(
      (ele) => ele.id === id
    )[0];
    let updatedFields = [];

    //   .filter((field) => field.label === "table")[0]
    const tableCells = tableData.cells.map((cell) =>
      cell.col === selectedCell.col ? { ...cell, label: selected.value } : cell
    );
    const headerCells = tableData.tableHeaders.map((cell) =>
      cell.col === selectedCell.col ? { ...cell, label: selected.value } : cell
    );
    updatedFields = {
      ...tableData,
      cells: tableCells,
      tableHeaders: headerCells,
    };

    for (let i = 0; i < categorisedFields.length; i++) {
      if ("table" in categorisedFields[i]) {
        // Find the object with the matching group i.e. "table"
        let dataObject = categorisedFields[i]["table"].data;

        if (dataObject) {
          // Update the dataObject with the new array [updatedFields]
          categorisedFields[i]["table"].data = [updatedFields];
        }
      }
    }

    const currentPageInfo = data.Pages.filter(
      (ele) => ele.pageNo === pageNumber
    )[0];
    // currentPageInfo.moderated = updatedFields;
    currentPageInfo.moderated = categorisedFields;

    // get fields of all pages to updateDocuments as moderated
    const moderatedFields = data.Pages.map((page) =>
      page.moderated.length > 0
        ? { id: page._id, moderated: page.moderated }
        : { id: page._id, moderated: page.transformed }
    );
    setPageFields(updatedFields);
    setModerated(moderatedFields);
    setCurrentTableData(updatedFields);
  };

  const setCurrentTableData = (fields) => {
    let tableInfo = "";
    if (fields && Array.isArray(fields)) {
      fields.map((field) => {
        if (field.hasOwnProperty("table")) {
          tableInfo = field.table.data[0];
        }
      });
    }

    const tableHeaders =
      tableInfo && tableInfo.cells.filter((cell) => cell.row === 1);
    // tableInfo && tableInfo.cells.filter((cell) => cell.row );
    if (Array.isArray(fields)) {
      setTableData({ ...tableInfo, tableHeaders: tableHeaders });
    } else {
      setTableData(fields);
    }
  };

  const deleteTableRow = () => {
    const updatedTableData =
      tableData &&
      tableData.cells.filter((ele) => !selectedTableRows.includes(ele.row));

    const updatedItems = qbItems.filter(
      (item) => !selectedTableRows.includes(item.row)
    );
    setQBItems(updatedItems);

    const selectedRowsSet = new Set(selectedTableRows);

    // Adjust row numbers of remaining rows
    updatedTableData.forEach((item, index) => {
      let newRow =
        item.row -
        Array.from(selectedRowsSet).filter((row) => row < item.row).length;
      updatedTableData[index] = { ...item, row: newRow };
    });

    const updatedTableFields = {
      ...tableData,
      cells: updatedTableData,
    };

    setSelectedTableRows([]);

    // Update categorisedFields with the new table data
    for (let i = 0; i < categorisedFields.length; i++) {
      if ("table" in categorisedFields[i]) {
        const dataObject = categorisedFields[i]["table"].data;
        if (dataObject) {
          categorisedFields[i]["table"].data = [updatedTableFields];
        }
      }
    }

    // Update currentPageInfo with the updated categorisedFields
    const currentPageInfo = data.Pages.find((ele) => ele.pageNo === pageNumber);
    currentPageInfo.moderated = categorisedFields;

    // Update moderatedFields with the updated data.Pages
    const moderatedFields = data.Pages.map((page) =>
      page.moderated.length > 0
        ? { id: page._id, moderated: page.moderated }
        : { id: page._id, moderated: page.transformed }
    );
    setModerated(moderatedFields);
    setCurrentTableData(updatedTableFields);
  };

  //function for add only one selected table row
  const AddTableRow = () => {
    if (selectedTableRows.length === 1) {
      const newRowObjArr = tableData.cells.filter(
        (item) => item.row === selectedTableRows[0]
      );

      if (newRowObjArr.length > 0) {
        const min = 1000;
        const max = 9999;
        const randomFourDigitNumber =
          Math.floor(Math.random() * (max - min + 1)) + min;
        const updatedObjects = newRowObjArr.map((item) => {
          return {
            ...item,
            row: item.row + 1,
            text: "",
            id: `${item.id}-${randomFourDigitNumber}`,
            verification_status: "edited",
            xmax: null,
            xmin: null,
            ymax: null,
            ymin: null,
          };
        });

        tableData.cells.map((item) => {
          if (item.row > selectedTableRows[0]) {
            item.row += 1;
          }
        });

        let ind = selectedTableRows[0] * updatedObjects.length;
        tableData.cells.splice(ind, 0, ...updatedObjects);

        const updatedTableFields = {
          ...tableData,
          cells: tableData.cells,
        };
        // setTableData(updatedTableFields);
        // setSelectedTableRows([]);

        // const allObj = allDetails.filter((ele) => ele.label !== "table");
        // let updatedFields = [...allObj, updatedTableFields];

        const currentPageInfo = data.Pages.filter(
          (ele) => ele.pageNo === pageNumber
        )[0];

        // currentPageInfo.moderated = updatedFields;
        currentPageInfo.moderated = categorisedFields;

        const moderatedFields = data.Pages.map((page) =>
          page.moderated.length > 0
            ? { id: page._id, moderated: page.moderated }
            : { id: page._id, moderated: page.transformed }
        );
        setModerated(moderatedFields);
        setCurrentTableData(updatedTableFields);
      }
    }
  };

  const deleteTableCol = (e, selectedCol) => {
    e.preventDefault();
    const updatedTableData = [];
    const updatedTableHeaders = [];

    if (tableData) {
      tableData.cells.forEach((ele) => {
        if (ele.col !== selectedCol) {
          updatedTableData.push({
            ...ele,
            col: ele.col > selectedCol ? ele.col - 1 : ele.col,
          });
        }
      });

      tableData.tableHeaders.forEach((ele) => {
        if (ele.col !== selectedCol) {
          updatedTableHeaders.push({
            ...ele,
            col: ele.col > selectedCol ? ele.col - 1 : ele.col,
          });
        }
      });
    }

    const updatedTableFields = {
      ...tableData,
      cells: updatedTableData,
      tableHeaders: updatedTableHeaders,
    };

    for (let i = 0; i < categorisedFields.length; i++) {
      if ("table" in categorisedFields[i]) {
        // Find the object with the matching group i.e. "table"
        let dataObject = categorisedFields[i]["table"].data;

        if (dataObject) {
          // Update the dataObject with the new array [updatedTableFields]
          categorisedFields[i]["table"].data = [updatedTableFields];
        }
      }
    }

    const currentPageInfo = data.Pages.filter(
      (ele) => ele.pageNo === pageNumber
    )[0];
    currentPageInfo.moderated = categorisedFields;

    const moderatedFields = data.Pages.map((page) =>
      page.moderated.length > 0
        ? { id: page._id, moderated: page.moderated }
        : { id: page._id, moderated: page.transformed }
    );
    setModerated(moderatedFields);
    setCurrentTableData(updatedTableFields);
  };

  const addTableCol = (e, selectedCol) => {
    e.preventDefault();
    if (selectedCol) {
      const newColObjArr = tableData.cells.filter(
        (item) => item.col === selectedCol
      );
      const newHeaderColObj = tableData.tableHeaders.filter(
        (item) => item.col === selectedCol
      );
      if (newColObjArr.length > 0) {
        const min = 1000;
        const max = 9999;
        const randomFourDigitNumber =
          Math.floor(Math.random() * (max - min + 1)) + min;
        const updatedObjects = newColObjArr.map((item) => {
          return {
            ...item,
            label: "",
            col: item.col + 1,
            text: "",
            id: `${item.id}-${randomFourDigitNumber}`,
            verification_status: "edited",
            xmax: null,
            xmin: null,
            ymax: null,
            ymin: null,
          };
        });

        const updatedHeaderObject = newHeaderColObj.map((item) => {
          return {
            ...item,
            label: "",
            col: item.col + 1,
            text: "",
            id: `${item.id}-${randomFourDigitNumber}`,
            verification_status: "edited",
            xmax: null,
            xmin: null,
            ymax: null,
            ymin: null,
          };
        });

        tableData.tableHeaders.map((item) => {
          if (item.col > selectedCol) {
            item.col += 1;
          }
        });

        tableData.cells.map((item) => {
          if (item.col > selectedCol) {
            item.col += 1;
          }
        });

        let ind = selectedCol * updatedObjects.length;

        tableData.cells.splice(ind, 0, ...updatedObjects);
        tableData.tableHeaders.splice(ind, 0, ...updatedHeaderObject);

        tableData &&
          tableData.tableHeaders.sort((a, b) => {
            if (a.row !== b.row) {
              return a.row - b.row;
            } else {
              return a.col - b.col;
            }
          });

        tableData &&
          tableData.cells.sort((a, b) => {
            if (a.row !== b.row) {
              return a.row - b.row;
            } else {
              return a.col - b.col;
            }
          });

        const updatedTableFields = {
          ...tableData,
          cells: tableData.cells,
          tableHeaders: tableData.tableHeaders,
        };

        for (let i = 0; i < categorisedFields.length; i++) {
          if ("table" in categorisedFields[i]) {
            // Find the object with the matching group i.e. "table"
            let dataObject = categorisedFields[i]["table"].data;

            if (dataObject) {
              // Update the dataObject with the new array [updatedTableFields]
              categorisedFields[i]["table"].data = [updatedTableFields];
            }
          }
        }
        const currentPageInfo = data.Pages.filter(
          (ele) => ele.pageNo === pageNumber
        )[0];

        currentPageInfo.moderated = categorisedFields;

        const moderatedFields = data.Pages.map((page) =>
          page.moderated.length > 0
            ? { id: page._id, moderated: page.moderated }
            : { id: page._id, moderated: page.transformed }
        );
        setModerated(moderatedFields);
        setCurrentTableData(updatedTableFields);
      }
    }
  };

  const handleCheckboxChange = (e, row) => {
    let updatedTableRows;
    if (e.target.checked) {
      updatedTableRows = [...selectedTableRows, row];
    } else {
      updatedTableRows = selectedTableRows.filter((ele) => ele !== row);
    }

    setSelectedTableRows(updatedTableRows);
    const numRows = updatedTableRows.length;
    setShowDeleteRowBtn(numRows > 0);
    setShowAddRowBtn(numRows > 0 && numRows < 2);
  };

  const getQuickBooksData = (buyerObj, itemsObj) => {
    if (buyerObj && Object.keys(buyerObj).length > 0) {
      for (let i = 0; i < categorisedFields.length; i++) {
        // Check if the current object has the specified key
        if ("buyer" in categorisedFields[i]) {
          // Find the object with the matching ID
          const dataObject = categorisedFields[i]["buyer"].data.find(
            (item) => item.label === "buyer_name"
          );

          // If the object is found, update the ocr_text property
          if (dataObject) {
            dataObject.ocr_text = buyerObj.customer;
          }
        }
      }
    }
    if (itemsObj && Object.keys(itemsObj).length > 0) {
      const tableCells =
        tableData &&
        tableData.cells.map((cell) =>
          cell.row === itemsObj.row && cell.label === "Description"
            ? { ...cell, text: itemsObj.name }
            : cell
        );

      let updatedTableFields = {
        ...tableData,
        cells: tableCells,
      };
      setTableData(updatedTableFields);

      for (let i = 0; i < categorisedFields.length; i++) {
        if ("table" in categorisedFields[i]) {
          // Find the object with the matching group i.e. "table"
          let dataObject = categorisedFields[i]["table"].data;

          if (dataObject) {
            // Update the dataObject with the new array [updatedTableFields]
            categorisedFields[i]["table"].data = [updatedTableFields];
          }
        }
      }
      if (qbItems.length > 0) {
        // Find the index of the element with the matching row
        const rowIndex = qbItems.findIndex((ele) => ele.row === itemsObj.row);

        if (rowIndex !== -1) {
          // If the row is present, update the existing object
          setQBItems((prevItems) => {
            const updatedItems = [...prevItems];
            updatedItems[rowIndex] = itemsObj;
            return updatedItems;
          });
        } else {
          // If the row is not present, push itemsObj to qbItems
          setQBItems((prevItems) => [...prevItems, itemsObj]);
        }
      } else {
        setQBItems([itemsObj]);
      }
    }
  };

  useEffect(() => {
    getDetails();
  }, []);

  useEffect(() => {
    getCurrentPageDetails(data);
  }, [pageNumber]);

  const previousHandler = () => {
    if (pageNumber > 0) {
      setPageNumber(pageNumber - 1);
    }
  };
  const nextHandler = () => {
    if (pageNumber < pagesLength - 1) {
      setPageNumber(pageNumber + 1);
    }
  };

  useEffect(() => {
    if (tableData) {
      const distinctRows = [
        ...new Set(tableData.cells && tableData.cells.map((item) => item.row)),
      ];
      setTableRowInfo({
        maxRow: distinctRows,
      });
    }
  }, [tableData]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const res = await api.get("/integrations/", {
  //         headers: {
  //           "x-access-token": auth.user.accessToken,
  //         },
  //       });
  //       if (res.status == 200) {
  //         setIsShowQB(res.data.integrations.quickbooksOnline.isLinked);
  //       }
  //       // setCustomerList(res.data.Customer);
  //     } catch (error) {
  //       console.log("Error fetching data:", error);
  //     }
  //   };

  //   fetchData();
  // }, []);

  useEffect(() => {
    if (tableData && tableData.cells) {
      let tableItemsArr = tableData.cells.filter(
        (ele) => ele.label === "Description"
      );
      setTableItems(tableItemsArr);
      let tableAmountArr = tableData.cells.filter(
        (ele) => ele.label === "Line_Amount"
      );
      setTableAmount(tableAmountArr);
    }
  }, [tableData]);

  const CategoryAndContent = (categoryName, customSectionKey) => {
    const categoryData =
      categorisedFields?.find((obj) => categoryName in obj)?.[categoryName]
        ?.data || [];
    return (
      <>
        {categoryData.length > 0 && (
          <div className="title" key={categoryName}>
            <span
              style={{ display: "inline-flex", textTransform: "capitalize" }}
            >
              {customSection[customSectionKey]}
              {/* <Editable
                text={customSection[customSectionKey]}
                id={categoryName}
                handler={updateSectionHandler}
                style={{ width: "100%", marginTop: "25px" }}
                editable={auth.isAdmin()}
              /> */}
            </span>
          </div>
        )}

        {categoryData.map((ele, i) => (
          <div className="info-wrapper" key={ele.id}>
            <span className="name">
              {ele.label ? ele.label.replaceAll("_", " ") : "NA"}
            </span>

            <EditableMultiline
              className="info"
              text={ele.ocr_text}
              id={categoryName}
              name={ele.label}
              handler={(e) => updateField(e, ele.id)}
              editable={auth.isAdmin()}
            />
          </div>
        ))}
      </>
    );
  };
  return (
    <DetailsWrapper>
      {!isLoading ? (
        <>
          <Content>
            <CanvasImage
              image={
                pageDetails &&
                pageDetails.images &&
                pageDetails.images.original_with_long_expiry
              }
              // image={pages.images.original && pages.images.original_with_long_expiry}
              info={allFields}
              rawOcr={pageDetails?.raw_ocr}
              resizeHandler={updateByBox}
              fieldlabels={fieldLabels.length > 0 && fieldLabels}
              tableHeaders={tableHeaders}
              updateHandler={updateByBox}
              deleteHandler={deleteByBox}
            />
          </Content>
          <SidebarWrapper ref={sidebarRef} style={{ width: sidebarWidth }}>
            <div className="resizer" onPointerDown={startResizing} />
            <Sidebar style={{ display: "flex" }}>
              <InvoiceSection className="paging-section">
                <Paging style={{ position: "unset", fontSize: "14px" }}>
                  <span
                    className={`prev ${pageNumber > 0 ? "primary" : "disable"}`}
                    onClick={previousHandler}
                  >
                    <ArrowLeftIcon />
                  </span>
                  <span>Page {pageNumber + 1}</span>
                  <span
                    className={`next ${
                      pageNumber < pagesLength - 1 ? "primary" : "disable"
                    }`}
                    onClick={nextHandler}
                  >
                    <ArrowRightIcon />
                  </span>
                </Paging>
                <DoxIconBtn
                  icon="close"
                  iconStyles={{ margin: "0px", padding: "0px" }}
                  handler={goBack}
                />
              </InvoiceSection>
              <InvoiceSection style={{ paddingTop: "15px", height: "100%" }}>
                <div
                  className="title"
                  style={{ justifyContent: "flex-end", gap: "10px" }}
                >
                  {/* {showIntegratedApps.quickbooksOnline && (
                    <img src={QuickBookIcon} className="qb" alt="QuickBook" />
                  )} */}
                  {pageDetails.moderated &&
                    pageDetails.moderated.length > 0 && (
                      <DownloadIcon
                        className={
                          pageDetails.moderated &&
                          pageDetails.moderated.length > 0
                            ? "download-csv"
                            : "disable"
                        }
                        handler={
                          pageDetails.moderated &&
                          pageDetails.moderated.length > 0 &&
                          getCsv
                        }
                      />
                    )}
                </div>
                <form className="details">
                  {category.map((categoryName) =>
                    CategoryAndContent(categoryName, categoryName)
                  )}

                  {tableData.tableHeaders && (
                    <TableContainer>
                      <div className="title">
                        <span
                          style={{
                            display: "inline-flex",
                            marginLeft: "10px",
                          }}
                        >
                          Table
                        </span>
                        <DoxBtn
                          text="Add Row"
                          className={showAddRowBtn ? "" : "disable"}
                          handler={AddTableRow}
                          style={{
                            height: "30px",
                            margin: "18px 5px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        />
                        <DoxBtn
                          text="Delete Row"
                          className={showDeleteRowBtn ? "" : "disable"}
                          handler={deleteTableRow}
                          style={{
                            height: "30px",
                            margin: "18px 5px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        />
                      </div>
                      {/* <div
                        className="table"
                        style={{
                          gridTemplateColumns: `repeat(${
                            tableData.tableHeaders &&
                            tableData.tableHeaders.length + 1
                          }, 1fr)`,
                        }}
                      > */}

                      <div style={{ overflowX: "scroll", overflowY: "hidden" }}>
                        <Table>
                          {body && body.documentType !== "BillOfLading" && (
                            <thead>
                              <TableRow>
                                <TableHead></TableHead>
                                {body.documentType === "Invoice" &&
                                  isShowQB &&
                                  buyerName && (
                                    <TableHead className="qb">
                                      <p
                                        style={{
                                          border: "2px solid #cfd1d3",
                                          borderRadius: "6px",
                                          // padding: "3px 10px",
                                          width: "130px",
                                          textAlign: "center",
                                          height: "50px",
                                          lineHeight: "50px",
                                        }}
                                      >
                                        Quickbooks
                                      </p>
                                    </TableHead>
                                  )}

                                {tableData.tableHeaders &&
                                  tableData.tableHeaders.map(
                                    (ele) =>
                                      ele.row === 1 && (
                                        <TableHead key={ele.id}>
                                          <TableHeaderConatiner>
                                            <div className="select_wrapper">
                                              <CustomSelectBox
                                                className="info"
                                                selected={ele}
                                                items={tableHeaders}
                                                handler={changeHeaderHandler}
                                              />
                                            </div>
                                            <div className="btn_wrapper">
                                              <Tooltip
                                                title="Add column to right side"
                                                placement="right"
                                              >
                                                <button
                                                  className="addBtn btn"
                                                  onClick={(e) =>
                                                    addTableCol(e, ele.col)
                                                  }
                                                >
                                                  <AddIcon className="icon" />
                                                </button>
                                              </Tooltip>
                                              <Tooltip
                                                title="Delete column"
                                                placement="right"
                                              >
                                                <button
                                                  className="deleteBtn btn"
                                                  onClick={(e) =>
                                                    deleteTableCol(e, ele.col)
                                                  }
                                                >
                                                  <DeleteIcon className="icon" />
                                                </button>
                                              </Tooltip>
                                            </div>
                                          </TableHeaderConatiner>
                                        </TableHead>
                                      )
                                  )}
                              </TableRow>
                            </thead>
                          )}

                          <tbody>
                            {tableRowInfo.maxRow &&
                              tableRowInfo.maxRow.map((rowNumber, ind) => {
                                return (
                                  <TableRow>
                                    <TableData>
                                      <input
                                        type="checkbox"
                                        className="checkbox"
                                        // checked={isChecked}
                                        onChange={(e) =>
                                          handleCheckboxChange(e, rowNumber)
                                        }
                                      />
                                    </TableData>
                                    {/* {body.documentType === "Invoice" &&
                                      isShowQB &&
                                      buyerName && (
                                        <TableData>
                                          <CustomDropDownPopUp
                                            tableItems={tableItems}
                                            tableAmount={tableAmount}
                                            row={rowNumber}
                                            showQB={isShowQB}
                                            buyerName={buyerName}
                                            handler={getQuickBooksData}
                                          />
                                        </TableData>
                                      )} */}

                                    {tableData.cells &&
                                      tableData.cells.map((ele) => {
                                        return (
                                          <>
                                            {ele.row === rowNumber ? (
                                              <TableData key={ele.id}>
                                                <EditableMultiline
                                                  className="info"
                                                  style={{
                                                    width: "150px",
                                                    display: "block",
                                                  }}
                                                  text={ele.text}
                                                  name={ele.label}
                                                  handler={(e) =>
                                                    updateField(e, ele.id, true)
                                                  }
                                                  editable={auth.isAdmin()}
                                                />
                                              </TableData>
                                            ) : null}
                                          </>
                                        );
                                      })}
                                  </TableRow>
                                );
                              })}
                          </tbody>
                        </Table>
                      </div>
                      {/* {tableData.tableHeaders &&
                          tableData.tableHeaders.map(
                            (ele) =>
                              ele.row === 1 && (
                                <div className="th" key={ele.id}>
                                  <CustomSelectBox
                                    className="info"
                                    selected={ele}
                                    items={tableHeaders}
                                    handler={changeHeaderHandler}
                                  />
                                </div>
                              )
                          )}
                        {tableData.cells &&
                          tableData.cells.map((td) => (
                            <div className="td" key={td.id}>
                              <EditableMultiline
                                className="info"
                                text={td.text}
                                name={td.label}
                                handler={(e) => updateField(e, td.id, true)}
                                editable={auth.isAdmin()}
                              />
                            </div>
                          ))} */}
                      {/* </div> */}
                    </TableContainer>
                  )}
                </form>
              </InvoiceSection>

              <InvoiceSection
                style={{
                  display: "flex",
                  // justifyContent: "end",
                  justifyContent: "space-between",
                  margin: 0,
                }}
              >
                {/* <CustomSearchDropdown
                  docType={body.documentType}
                  showQB={isShowQB}
                  buyerName={buyerName}
                  handler={getQuickBooksData}
                /> */}

                {auth.isAdmin() && (
                  <DoxBtn
                    text="submit"
                    icon="check"
                    handler={submitHandler}
                    style={{
                      // margin: "18px 5px",
                      margin: "32px 5px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  />
                )}
              </InvoiceSection>
            </Sidebar>
          </SidebarWrapper>
        </>
      ) : (
        <WidgetsLoader />
      )}
    </DetailsWrapper>
  );
};

export default DocumentDetail;
